.friend-images {
  width: 12rem;
  height: auto;
}

.product-thumb {
  height: 168px;
  margin-left: 2rem;
  margin-right: 2rem;
}

.friend-thumb {
  height: 5rem;
}

.image-container {
  position: relative;
  display: inline-block;
}

.img-responsive.genre-image-new {
  background-color: black;
}

.image-info {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-info {
  opacity: 1;
}

/* .image-info h5,
.image-info h6 {
  margin: 5px;
} */

.image-info h5,
.image-info h6 {
  margin: 5px;
  font-size: 1.1em; /* Increase font size */
  font-weight: 600; /* Make the text bolder */
  line-height: 1.4; /* Adjust line spacing */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add a subtle text shadow for better readability */
}

/* 
.white-box  {
  display: block;
  padding: 4px;
  margin: 20px 0;
  background-color: #f8c17e;
  border: 1px solid #F69827;
  border-radius: 0px;
  padding:20px;
} */

.white-box {
  display: block;
  padding: 4px;
  margin: 20px 0;
  background-color: #1e1d1d;
  border: 1px solid #1e1d1d;
  /* color: #F69827; */
  color: white;

  border-radius: 0px;
  padding:20px;
}

.pagination-style {
  color: white;
  border-radius: 0px;
  padding:20px;
  justify-content: center; 
  text-align: center;
}

/* .white-box {
  display: block;
  padding: 4px;
  margin: 20px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding:20px;
} */

.margin-1 {
  margin: 1rem;
}

.no-margin {
  margin: 0;
}

.no-padding{
  padding: 0 !important;
  line-height: 0 !important;
}

.product-padding{
  padding: 20px !important;
}

.product-price h4{
  font-weight: 300 !important;
}

.customnail {
	margin: 20px 0 10px 0 !important;
	width: 100%;
	height: auto;
	/* border-style: solid;
    border-width: 1px; */
	padding: 10px;
    /* border-color: #DCDCDC; */
}

.customnail-new {
	/* margin: 20px 0 10px 0 !important; */
	width: 100%;
	height: auto;
	/* border-style: solid;
    border-width: 1px; */
	padding: 10px;
    /* border-color: #DCDCDC; */
}

.textCustom {
  width: 100%;
}

.image-info-mobile {
  display: none;
}

@media (max-width: 768px) {
  .image-info {
    display: none;
  }
  .image-info-mobile {
    display: block;
    text-align: center;
    color: white;
    margin-top: 10px;
  }
}