.full-width {
  width: 100%;
  height: auto;
}

.top-hero-padding {
  padding-top: 3rem;
}

.relative-width {
  width: 1100px;
  height: 45vw;
}

.row.display-flex {
	display: flex;
	flex-wrap: wrap;
  }

.row.display-flex-new {
  background-color: black;
  }

.row.display-flex-new2 {
  background-color: rgb(192, 6, 6);
}

/* .row.display-fle.new{
  margin: 0;
} */

/* .text-black {
  color: black;
} */