/* @media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
    height: 900px;
    padding-top: 30px;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 400px;
  }
}

.Signup {
  height: 900px;
  padding-top: 30px;
} */


@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
    height: 900px;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 400px;
  }
}

.Signup {
  padding: 60px 0;
  height: 900px;
}

/* @media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
    height: 900px;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 400px;
  }
}

.Signup {
  padding: 60px 0;
  height: 900px;
}

.Signup form {
  margin: 0 auto;
  max-width: 350px;
}

.Signup form span.help-block {
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #999;
} */