/* App.css */
.product-card {
  text-align: left;
  margin-bottom: 30px;
}

.product-price {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}

.product-features {
  font-size: 22px;
  margin: 20px 0;
  text-align: left;
}

.product-card ul {
  list-style-type: none;
  padding: 0;
}

.product-card li {
  margin-bottom: 10px;
}
