.thumbs {
  width: 8rem;
  height: auto;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.switch {
	width: 50px;
	height: 24px;
	background-color: #ccc;
	border-radius: 12px;
	position: relative;
	cursor: pointer;
  }
  
  .switch-button {
	width: 22px;
	height: 22px;
	background-color: white;
	border-radius: 50%;
	position: absolute;
	top: 1px;
	left: 1px;
	transition: 0.3s;
  }
  
  .switch-button.active {
	left: 27px;
  }
  
.centered-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40vh;
	width: 100%;
  }
/*   
.centered-button {
	margin: 0 auto;
} */
.centered-button {
	display: inline-block;
	font-size: 18px;
	/* padding: 12px 24px; */
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 50%; /* Updated border-radius */
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s, transform 0.3s;
  }
  
  .position-relative {
	position: relative;
  }
  
  .position-absolute {
	position: absolute;
  }
  
  .button-container {
	top: 50%;
	right: 0;
	transform: translateY(-50%);
  }
  
  .scene-button {
	margin-bottom: 10px;
  }
  
/* Media query for mobile screen sizes */
@media (max-width: 767px) {
	.button-container {
	  position: absolute;
	  top: 92%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
  }
  
  .top-bar {
	position: fixed;
	/* top: 0;
	left: 0; */
	width: 100%;
	height: 70px;
	background-color: #333;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	/* transition: transform 0.3s ease-in-out; */
	font-size: 22px; /* default font size for non-mobile screens */
	font-family:Arial;
  }
  
  @media (max-width: 767px) { /* apply styles for mobile screens */
	.top-bar {
	 color: white;
	  font-size: 14px; /* reduce font size for mobile screens */
	  font-family:Arial;
	}
  }

  .centered-button:hover {
	background-color: #0056b3;
	transform: scale(1.05);
  }
  
  .centered-button:focus {
	outline: none;
  }
  
.customnail {
	margin: 20px 0 10px 0 !important;
	width: 100%;
	height: auto;
	/* border-style: solid;
    border-width: 1px; */
	padding: 10px;
    /* border-color: #DCDCDC; */
}

.customnai-new {
	margin: 20px 0 10px 0 !important;
	width: 100%;
	height: 2000px;
	/* border-style: solid;
    border-width: 1px; */
	padding: 10px;
    /* border-color: #DCDCDC; */
}

.new-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.image {
    float: left;
	width: 100%;

    /* width:  300px; */
    height: 350px;
    /* object-fit: cover; */
	margin-bottom: 15px;
}

/* .bottom-div {
	margin-top: 400px;
    align-self: flex-end;
    padding: 10px;
} */


.GenreImage {
    float: left;
	width: 100%;

    /* width:  400px; */
    height: 275px;
    /* object-fit: cover; */
	margin-bottom: 15px;
}

@media (min-width: 800px) {
	.GenreImage {
		float: left;
		width: 100%;
	
		/* width:  400px; */
		height: 339px;
		/* object-fit: cover; */
		margin-bottom: 15px;
	}	
  }

.StoryImage {
    float: left;
	width: 105%;

    /* width:  400px; */
    height: 275px;
    /* object-fit: cover; */
	margin-bottom: 15px;
}

@media (min-width: 700px) {
	.StoryImage {
		float: left;
		width: 105%;
		height: 539px;
		margin-bottom: 15px;
	}	
  }

  
.CustomViewImage {
    float: left;
	width: 45%;
	margin-left: 40px;
	margin-right: 15px;
	margin-bottom: 10px;
    height: 820px;
}


.CustomViewImageStoryNew {
	width: auto;
    height: 400px;
}

@media (min-width: 900px) {
	.CustomViewImageStoryNew {
	  width: 100%;
	  height: 900px;
	  object-fit: cover;
	}
  }


/* .CustomViewImageStoryNew {
	width: 100%;
    height: 500px;
}

@media (min-width: 900px) {
	.CustomViewImageStoryNew {
	  width: 100%;
	  height: 612px;
	}
  } */

.CustomViewImageNew {
	width: 100%;
    height: 700px;
}

/* Reduce image size for high-resolution screens */
@media (min-width: 900px) {
	.CustomViewImageNew {
	  width: 70%;
	  height: 1282px;
	}
  }

.CustomViewImagePanel {
	width: 360px;
	height: 610px;
    /* height: auto; */
}

/* Reduce image size for high-resolution screens */
@media (min-width: 800px) {
	.CustomViewImagePanel {
	 padding-left: 360px;
	  width: 80%;
	  height: 1290px;
	}
  }

.center {
  padding-top: 1rem;
  display: flex;
  justify-content: space-around; 
}

.top-padding {
  padding-top: 1rem;
}

.ad-gallery {
	padding: 20px;
	-moz-box-shadow:    inset 0 0 50px #F69827;
  -webkit-box-shadow: inset 0 0 50px #F69827;
  box-shadow:         inset 0 0 50px #F69827;
}

.padding-5 {
	padding: 5px !important;
}

.well-bs {
	background-color: #1e1d1d;
	padding: 20px;
	border-radius: 0px !important;
}

.well-bs h3 {
	color: #000;
	margin:0 0 0px 0;
	font-weight: 400;
}

.well-bs a {
	color: #F69827 !important;
	margin:0 0 0px 0;
	font-weight: 400;
	font-size: 14px !important;
}

.container-category {
	padding: 20px;
	/* border: 1px #ddd solid; */
	background-color: #1e1d1d;
	color: white;
}

.container-category h3 {
	margin: 0px;
}

.friend-thumb { 
	border-radius: 50%;
}

.no-border{
	border: 0 !important;
	-moz-box-shadow:    inset 0 0 0px #f5f6f9 !important;
  -webkit-box-shadow: inset 0 0 0px #f5f6f9 !important;
  box-shadow:         inset 0 0 0px #f5f6f9 !important;
}

.no-padding-top{
	padding-top:0px;
}

.no-margin-bottom{
	margin-bottom:0px !important;
}

.no-padding-bottom{
	padding-bottom:0px !important;
}

.padding-50{
	padding: 80px !important;
}

.rating-container{
  padding: 0 11%;
}

.btn-black{
	background-color:#3b3b3b;
	border:1px solid #000000;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:13px;
	padding:10px 19px;
	text-decoration:none;
	width: 120px;
	border-radius:0 !important;
}

.btn-black-center{
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.btn-black:hover {
	color:#ffffff !important;
}

.btn-black:active {
	color:#ffffff !important;
}

.btn-black:focus {
	color:#ffffff !important;
}

.btn-transparent {
	color:blue;
}

a {
	font-size: 13px;
}

a:hover {
	text-decoration: none !important;
}

.loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #F69827; /* orange */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    z-index: 1;
    margin-top: 30px;
}

.loader-no-margin {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #F69827; /* orange */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    z-index: 1;
}

.padding-bottom-120 {
  padding-bottom: 120px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.order-date h4 {
	margin: 40px 0 0 2px;
	font-weight: 350;
	color:white;
}

/* .full-page {
	height: 100%; 
    position: fixed;
    width: 1140px;
} */

.checkout-padding {
	padding-right:23px;
	text-align:right;
}

.padding-total {
	padding: 10px 0;
}

.quantity-style {
	border:1px solid #eee !important;
	background: transparent !important;
	border-radius: 4px;
}
