@media all and (min-width: 480px) {
  .LoginNew {
    padding: 60px 0;
    height: 1900px;
  }

  .LoginNew form {
    margin: 0 auto;
    max-width: 400px;
  }
}

.LoginNew {
  padding: 60px 0;
  height: 900px;
}

.form-control {
  border-radius: 0 !important;
}

.btn-default{
	background-color: #F69827 !important;
	border-radius: 0 !important;
	color: #fff !important;
	border: 0;
	background-image: none !important;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default[disabled].active, fieldset[disabled] .btn-default.active{
		background-color: #F69827 !important;
		opacity: 1.00 !important;
}