.Home .lander {
  padding: 80px 0;
  text-align: left;
}

.relative-width {
  padding: 10px 10px 10px 50px !important;
  width: 97%;
  height: 45vw;
}

.container-category img{
  width: 100%;
  cursor: pointer;
}

.Home .product-section{
  padding: 30px 0;
}

.Home .product-section h2{
  padding: 10px 0;
  font-family:Arial;
  font-weight: 300;
  text-align: center;
}

.Home .lander h1 {
  font-size:52px;
  text-align: center;
  font-family:Arial;
  font-weight: 300;
}

.ad-container-padding{
  padding: 0 8px 14px 8px !important;
}

.ad-padding{ 
  padding: 0 7px !important;
}

.Home .lander p {
  color: #555;
  font-size:18px;
  line-height: 28px;
  padding: 40px 15px;
  font-size: 1.28em;
  font-weight: 200;
  line-height: 1.6em;
}

.Home a {
  font-size:18px;
  line-height: 28px;
  font-weight: 200;
  line-height: 1.6em;
}

.Home .product-section p{
  padding: 10px 0;
}

.Home .notes h4 {
  font-family: Arial;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Home .notes p {
  color: #666;
}

.Home h3 a {
  color: #005b86;
  font-size: 1.2em;
}

.Home h3 {
  color: #005b86;
  font-weight: 300;
  padding: 0px 0px;
}

.Home h4 {
  font-weight: 500;
  padding: 0px 0px;
}

.Home .button-container{
  text-align: center;
  padding: 0px 0 60px 0;
}

.Home .button-container a{
  padding: 10px 20px;
  background-color: #F69827;
  border: 0;
  color:#fff;
  font-weight: 400;
  font-size: 16px;
}

.button-container a{
  padding: 10px 20px;
  background-color: #F69827;
  border: 0;
  color:#fff;
  font-weight: 400;
  font-size: 16px;
}

.button-container-new a{
  background-color: #F69827;
  border: 0;
  color:#fff;
  font-weight: 400;
  font-size: 22px;
}

/* Create four equal columns that floats next to each other */
.column {
  float: left;
  width: 45%;
  padding: 10px;
  height: auto; /* Should be removed. Only for demonstration */
}

.column-image {
  float: left;
  width: 55%;
  padding: 20px;
  height: auto; /* Should be removed. Only for demonstration */
}

.column-image-width {
  width: 95%;
  height: auto;
  padding: 10px;
}

.row {
  width: 100%;
  padding: 20px;
}

.row-custom-view-page {
  width: 100%;
  display: flex;
  /* padding: 20px; */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .column, .column-image, .right {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}

.p-style{
	font-size: 20px !important;
  text-align: center;
}

.text-font{
	font-size: 24px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-font-small{
	font-size: 16px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-black{
	font-size: 22px !important;
  font-family:Arial;
  text-align: left;
  color: black;
}

.text-white-text{
	font-size: 17px !important;
  font-family:Arial;
  text-align: left;
  color: white;
  padding-top: 30px;
}

@media (min-width: 900px) {
	.text-white-text {
    font-size: 32px !important;
    font-family:Arial;
    text-align: left;
    color: white;
    padding-top: 30px;
	}
}

.text-white{
	font-size: 22px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-white-medium{
	font-size: 19px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-white-header{
  font-family:Arial;
  color: white;
}

.Home .button-container{
  text-align: center;
  padding: 0px 0 60px 0;
}

.button-container{
  text-align: center;
  padding: 0px 0 60px 0;
}

.text-black-color{
  color: black;
  font-family:Arial;
  font-size: 20px;
}

.text-black-header{
  color: black;
  font-family:Arial;
  font-size: 25px;
}

.navbar-default{
  border: 0px !important;
  background: transparent !important;
  box-shadow: inset 0 0px 0 rgba(255, 255, 255, 0), 0 1px 5px rgba(0, 0, 0, 0) !important;
  -webkit-box-shadow: inset 0 0px 0 rgba(255, 255, 255, 0), 0 1px 5px rgba(0, 0, 0, 0) !important;
  margin: 0 !important;
}

.spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}