.faq-item {
  /* background-color: #333; */
  font-size: 16px;
  /* color: #f1f1f1; */
  /* border: 1px solid #444; */
  margin-bottom: 20px;
}

.faq-item .panel-heading {
  font-size: 29px;
  /* background-color: #222; */
  /* border-bottom: 1px solid #444; */
  /* font-weight: bold; */
}

.column-wrapper {
  padding-left: 60px;
  padding-right: 60px;
}


@media (max-width: 768px) {
  .column-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}