/* .character-image {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }
  
  .character-name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  } */
  .character-image {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
  
  .character-name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
  }
  
  .character-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }