/* @media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 580px;
    color: #F69827;
  }
} */

.Login {
  padding: 60px 0;
  height: 50%;
}

.Login form {
  margin: 0 auto;
  max-width: 980px;
  color: #F69827;
  height: 50%;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    height: 50%;
  }

  .Login form {
    margin: 0 auto;
    max-width: 800px;
    color: #F69827;
    height: 50%;
  }
}

.dropdown-menu {
  height: auto;
  max-height: 200px;  /* Or whatever maximum height you want */
  overflow-x: hidden;
}

.form-control {
  border-radius: 0 !important;
  
}

.btn-default{
	background-color: #F69827 !important;
	border-radius: 0 !important;
	color: #fff !important;
	border: 0;
	background-image: none !important;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default[disabled].active, fieldset[disabled] .btn-default.active{
		background-color: #F69827 !important;
		opacity: 1.00 !important;
}

.Category {
  color: #000;
}

.CustomStoryContainer {
  /* color: #000; */
  /* border: #fff; */
  border-radius: 0 !important;
  border-color: #fff;
  border: 20px solid transparent;
  transition: border-color 0.3s ease;
}


.genre-image {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.genre-image-new {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  background-color: black;

}

.genre-image.selected {
  border-color: #007bff;
}

.story-type-btn {
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.story-type-btn.selected {
  border-color: #007bff;
}