.App {
  /* margin-top: 15px; */
  width: 100%;
  background-color: #1e1d1d;
}

/* *{
  outline: 1px solid red;
 }
  */
.App .navbar-brand {
  font-weight: bold;
}

.App .orange-old {
  color: #F69827;
}

.App .orange {
  color: #F69827;
  font-size: medium;
}

.custom-footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  font-size: 18px !important;
  font-family:Arial;
  text-align: left;
}

.footer-img {
  max-width: 100%;
  /* height: auto; */
  /* margin-bottom: 20px; */
}

.about-section,
#formEmail,
#formMessage {
  margin-bottom: 5px;
}


.relative-width {
  width: 5%;
  height: 15vw;
}

.App .white {
  color: #fff;
}

.custom-footer {
  text-align: center;
  color: white;
  padding: 3px;
  background-color: #F69827;
}

.footer-content {
  display: inline-block;
  text-align: left;
}

.shopping-icon-container{
	background-color: #F69827;
	padding: 2px 12px 0 12px;
	border-radius: 14px;
	margin-right: -24px;
}

.shopping-icon-container .count{
	margin: 0 0 0 4px;
}

.line-height-24{
	line-height: 24px;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a:active{
  background-color: transparent !important;
  background-image: none !important;
  box-shadow:inset 0 0px 0px rgba(0, 0, 0, .00) !important;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a:focus{
  background-color: transparent !important;
  background-image: none !important;
  box-shadow:inset 0 0px 0px rgba(0, 0, 0, .00) !important;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a:focus-within{
  background-color: transparent !important;
  background-image: none !important;
  box-shadow:inset 0 0px 0px rgba(0, 0, 0, .00) !important;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a:visited{
  background-color: transparent !important;
  background-image: none !important;
  box-shadow:inset 0 0px 0px rgba(0, 0, 0, .00) !important;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a {  
  background-color: transparent !important;
  background-image: none !important;
  box-shadow:inset 0 0px 0px rgba(0, 0, 0, .00) !important;
}