.btn-btn-black {
  background-color: #3b3b3b !important;
  border: 1px solid #000000 !important;
  display: inline-block;
  color: #ffffff !important;
  font-size: 13px;
  padding: 10px 19px;
  width: 120px;
  background-image: none !important;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 20px; /* Provide some spacing */
  width: 100%;
}

.faq-item {
  width: 100%; /* Take the full width of the parent */
}

.faq-question {
  display: block;
  background: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  /* border: 2px solid white; */
  width: 100%; /* Take the full width of the parent */
  text-align: left;
}

.faq-question.active {
  background: #333;
}

.faq-answer {
  padding: 10px;
  margin-bottom: 20px; /* Add some space after each answer */
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust this value to set the max-width of the iframe */
  padding-bottom: 500px; 
  /* 16:9 Aspect Ratio */
  margin: 0 auto; /* Center the container horizontally */
  overflow: hidden;
}

.custom-button {
  margin-left: 10px ;
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  color: white;
  background-color: #F69827;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 4px;
}

.custom-panel-button {
  margin-left: 10px ;
  padding: 10px 20px;
  font-size: 40px;
  border: none;
  color: white;
  background-color: #F69827;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 4px;
}

.custom-button:hover {
  background-color: #0056b3;
}

@media all and (min-width: 480px) {
  .custom-button {
    margin-left: 10px ;
    padding: 10px 20px;
    font-size: 30px;
    border: none;
    color: white;
    background-color: #F69827;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .custom-button:hover {
    background-color: #0056b3;
  }
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.no-friends-padding {
  padding-top: 80px;
}

.cool-title-black {
  color: #ffffff;
  font-family: 'Arial'; /* Choose a suitable font family */
  font-size: 4.0rem;
  font-weight: bold;
  text-align: center;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  background: linear-gradient(120deg, black, black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 20px 0;
}

.cool-title-black-small {
  color: #ffffff;
  font-family: 'Arial'; /* Choose a suitable font family */
  font-size: 3.0rem;
  font-weight: bold;
  text-align: center;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  background: linear-gradient(120deg, black, black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 20px 0;
}


.cool-title {
  color: #ffffff;
  font-family: 'Arial'; /* Choose a suitable font family */
  font-size: 4.5rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background: linear-gradient(120deg, #F69827, #f5cd9d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 20px 0;
}

.cool-title-small {
  color: #ffffff;
  font-family: 'Arial'; /* Choose a suitable font family */
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background: linear-gradient(120deg, #F69827, #f5cd9d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 20px 0;
}
/* 
@keyframes scrolling {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.continuous-scroll {
  display: flex;
  animation: scrolling 15s linear infinite;
  overflow: hidden;
} */


.text-black{
	font-size: 22px !important;
  font-family:Arial;
  text-align: left;
  color: black;
}

.text-black-medium{
	font-size: 19px !important;
  font-family:Arial;
  text-align: left;
  color: black;
}

.text-white-medium{
	font-size: 20px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-white-large{
	font-size: 24px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-white-small{
	font-size: 15px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

.text-white-footer{
	font-size: 12px !important;
  font-family:Arial;
  text-align: left;
  color: white;
}

@media all and (min-width: 480px) {
  .text-white-footer{
    font-size: 18px !important;
    font-family:Arial;
    text-align: left;
    color: white;
  }
}

.text-white-verysmall{
	font-size: 12px !important;
  font-family:Arial;
  text-align: center;
  color: white;
}

@media all and (min-width: 480px) {
  .text-white-verysmall{
    font-size: 18px !important;
    font-family:Arial;
    text-align: left;
    text-align: center;
    color: white;
  }
}

.text-white-customheader{
  font-size: 21px !important;
  font-family:Arial;
  text-align: center;
  color: white;
}

@media all and (min-width: 480px) {
  .text-white-customheader{
    font-size: 38px !important;
    font-family:Arial;
    text-align: center;
    color: white;
  }
}

.text-black-small{
	font-size: 16px !important;
  font-family:Arial;
  text-align: left;
  color: black;
}

.banner {
  position: relative;
  background-color: #1e1d1d;
  overflow: hidden;
  /* padding: 50px 0; */
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background-image: linear-gradient(60deg, #84fab0 0%, #8fd3f4 50%, #84fab0 100%);
  transform: rotate(-15deg);
  animation: slide 20s linear infinite;
  z-index: -1;
}

@keyframes slide {
  0% {
    transform: rotate(-15deg) translateX(-50%);
  }
  100% {
    transform: rotate(-15deg) translateX(50%);
  }
}